import Component from '../models/Component';
import $ from 'jquery';

export default class FullContainerVideo extends Component {
  constructor(element, context) {
    super(element, context);
    this.initializeYouTubePlayer();
  }

  initializeYouTubePlayer() {
    // Memorizza il riferimento alla classe per uso nelle callback
    const self = this;
    
    // Verifica se l'API è già caricata
    if (window.YT && window.YT.Player) {
      this.createPlayer();
    } else {
      // Crea o aggiorna la callback onYouTubeIframeAPIReady
      // if (!complianzYT){
      //   console.log('accetta complianz');
      // }else{
      //   console.log('errore generico');
      // }
      const oldCallback = window.onYouTubeIframeAPIReady || function() {};
      window.onYouTubeIframeAPIReady = function() {
        oldCallback();
        self.createPlayer();
      };
    }
  }

  createPlayer() {
    $(() => {
      const play = this.element.find('.play-button');
      const pause = this.element.find('.pause-button');
      const element = this.element.find('#player');
      const id = element.attr('data-id');
      
      const player = new YT.Player('player', {
        videoId: id,
        height: window.innerWidth > 768 ? '720' : '350',
        width: '1280',
        playerVars: {
          'html5': 1,
          'controls': 0,
          'modestbranding': 1,
          'autoplay': 0,
          'rel': 0,
          'showinfo': 0,
          'listType': 'WEGHGroup',
        },
        events: {
          'onStateChange': (event) => {
            if (event.data == YT.PlayerState.ENDED) {
              $(play).show();
              $(pause).hide().removeClass('flex');
            }
          }
        }
      });

      $(play).on('click', function() {
        player.playVideo();
        $(play).hide();
        $(pause).show().addClass('flex');
      });

      $(pause).on('click', function() {
        player.pauseVideo();
        $(play).show();
        $(pause).hide().removeClass('flex');
      });
    });
  }
}